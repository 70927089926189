// consts
import {
  teaserI18n,
  appPromotionTooli18n,
  theTradeTeaserI18n,
  a11yMenuI18n,
  alertsI18n,
  adsI18n,
  audioPlayerI18n,
  BingI18n,
  breadcrumbsI18n,
  cancellationModalI18n,
  clickTrackerI18n,
  commentI18n,
  commentFormI18n,
  commentSentI18n,
  commentsSectionI18n,
  faceExclusivesI18n,
  footerDesktopI18n,
  footerSocialLinksI18n,
  footerMobileListsI18n,
  fryListI18n,
  giftShareI18n,
  greetingsI18n,
  headerSearchI18n,
  hotTopicsI18n,
  homepageLogoLink,
  magazineExclusivesI18n,
  mastheadSubscriptionI18n,
  mobileAdditionalShare,
  mobileNavigationMenuI18n,
  mobileQuickRegistrationI18n,
  mobileSearchI18n,
  mobileUserMenuI18n,
  mobileReadingList,
  myAlertsI18n,
  navigationMenuI18n,
  newsletterI18n,
  singleNewsletterI18n,
  osakaI18n,
  paywallI18n,
  paywallMiniregI18n,
  paywallPhoneForArticleI18n,
  promotionsI18n,
  seriesArticleI18n,
  serviceByMailI18n,
  tagsElementI18n,
  textInputI18n,
  userMenuI18n,
  userStoryTopicI18n,
  welcomePageI18n,
  zenTextI18n,
  zoominText,
  zoomoutText,
  seoI18n,
  selectAriaLabel,
  articleLayoutI18n,
  authorPage,
  breakingNewsStrip,
  dayMap,
  coastHeaders,
  commentsCountI18n,
  commercialListI18n,
  galleryI18n,
  shareBarI18n,
  sliderCaptionI18n,
  liveBlogI18n,
  magazineRecipesI18n,
  podcastChannelNames,
  podcastChannelTitle,
  podcastChannelTitleMobile,
  podcastI18n,
  ratingI18n,
  survey,
  surveyPie,
  readingListActionsI18n,
  readingListMenuI18n,
  readingListPageI18n,
  recipeIngredientsI18n,
  recipeInstructionsI18n,
  recipeRatingI18n,
  recipesSearchI18n,
  reviewRatingI18n,
  tldrDefaultContent,
  tldrPromotionLink,
  topNewsI18n,
  marketingTools,
  footballHeaders,
  nbaHeaders,
  groupBarTabs,
  playoffsI18n,
  btnOptions,
  timeLabels,
  zenNonPayingUsers,
  printNonPayingUsers,
  readingListDisabled,
  adBlockPage,
  timeDuration,
  timeDurationEmpty,
  pushwooshCustomPopup,
  validationMailStrip,
  words,
  tateI18n,
  infographicsI18n,
  algoliaI18n,
  commentsPaywallBlocker,
  errorPageI18n,
  imageGallery,
  labelsI18n,
  gameArticleI18n,
  personalAreaI18n,
  downloadApplicationPopupI18n,
  resetPasswordModalI18n,
  authorTooltipI18n,
  conferenceArticleI18n,
  fomoToolTipI18n,
} from './consts/i18n';

import {
  listStyle,
  listViewHeader,

  boxyStyle,
  barbadosStyle,
  calculonStyle,
  conradAndWongStyle,
  donbotStyle,
  spotStyle,
  hawkingStyle,
  marcoStyle,
  michelleStyle,
  mousepadStyle,
  robertoStyle,
  panucciStyle,
  pazuzuStyle,
  santaStyle,
  slugsStyle,
  tateStyle,
  verticalList,
  weekendStyle,
  zappStyle, } from './consts/listStyle';
import abnerStyle from './consts/abnerStyle';
import getDelay from './methods/getDelay';
import alertsStyle from './consts/alertsStyle';
import appPromotionToolStyle from './consts/appPromotionToolStyle';
import articleStyle from './consts/articleStyle';
import audioPlayerStyle from './consts/audioPlayerStyle';
import bps from './consts/bps';
import breadCrumbsStyle from './consts/breadCrumbsStyle';
import breakingNewsStyle from './consts/breakingNewsStyle';
import breakingNewsNotificationsStyle from './consts/breakingNewsNotificationsStyle';
import btnStyle from './consts/btnStyle';
import captionStyles from './consts/captionStyles';
import cardStyle from './consts/cardStyle';
import commentsStyle from './consts/commentsStyle';
import debtPopup from './consts/debtPopup';
import faceArticleStyle from './consts/faceArticleStyle';
import fontStacks from './consts/fontStacks';
import footerStyle from './consts/footerStyle';
import getColor from './methods/getColor';
import getDuration from './methods/getDuration';
import getMqString from './methods/getMqString';
import getTimingFunction from './methods/getTimingFunction';
import getTransition from './methods/getTransition';
import getTransitionString from './methods/getTransitionString';
import getZIndex from './methods/getZIndex';
import gridStyle from './consts/gridStyle';
import inputStyle from './consts/inputStyle';
import layoutStyle from './consts/layoutStyle';
import liveBlogArticleStyle from './consts/liveBlogArticleStyle';
import recipeArticleStyle from './consts/recipeArticleStyle';
import magazineArticleStyle from './consts/magazineArticleStyle';
import mastheadStyle from './consts/mastheadStyle';
import marketingBannerStyle from './consts/marketingBannerStyle';
import mobileStyle from './consts/mobileStyle';
import navMenuStyle from './consts/navMenuStyle';
import mq from './methods/mq';
import myAlertsStyle from './consts/myAlertsStyle';
import newsletterStyle from './consts/newsletterStyle';
import contactUsStyle from './consts/contactUsStyle';
import osakaStyle from './consts/osakaStyle';
import pageDateTime from './consts/pageDateTime';
import paywallStyle from './consts/paywallStyle';
import paywallMidpageStyle from './consts/paywallMidpage';
import podcastTeasersStyle from './consts/podcastTeasersStyle';
import pxToRem from './methods/pxToRem';
import ratingStyle from './consts/ratingStyle';
import readingListStyle from './consts/readingListStyle';
import resetPasswordModalStyle from './consts/resetPasswordModalStyle';
import searchEngineStyle from './consts/searchEngineStyle';
import selectStyle from './consts/selectStyle';
import serviceByMailStyle from './consts/serviceByMailStyle';
import specialPromotionStyle from './consts/specialPromotionStyle';
import teaserStyle from './consts/teaserStyle';
import tabElementStyle from './consts/tabElementStyle';
import timelineArticleStyle from './consts/timelineArticleStyle';
import topNewsStyle from './consts/topNewsStyle';
import quoteStyle from './consts/quoteStyle';
import typeConf from './consts/typeConf';
import shareBarPopup from './consts/shareBarPopup';
import subscribePopupStyle from './consts/subscribePopupStyle';
import typesetter from './methods/typesetter';
import coronaHomeStory from './consts/coronaHomeStory';
import personalAreaStyle from './consts/personalAreaStyle';
import reviewArticleStyle from './consts/reviewArticleStyle';
import conferenceArticleStyle from './consts/conferenceArticleStyle';

const tmTheme = Object.freeze({
  // Constants
  abnerStyle,
  alertsStyle,
  appPromotionToolStyle,
  articleStyle,
  audioPlayerStyle,
  authorPage,
  bps,
  breadCrumbsStyle,
  breakingNewsStyle,
  breakingNewsNotificationsStyle,
  barbadosStyle,
  boxyStyle,
  btnStyle,
  captionStyles,
  cardStyle,
  calculonStyle,
  conradAndWongStyle,
  donbotStyle,
  spotStyle,
  commentsStyle,
  debtPopup,
  direction: 'rtl',
  hawkingStyle,
  gridStyle,
  fontStacks,
  footerStyle,
  // font for elements that are framed (mainly relevant for haaretz.com)
  framedFont: 'base',
  inputStyle,
  imageGallery,
  listStyle,
  listViewHeader,
  layoutStyle,
  liveBlogArticleStyle,
  recipeArticleStyle,
  logoType: 'tm',
  magazineArticleStyle,
  mastheadStyle,
  marcoStyle,
  robertoStyle,
  marketingBannerStyle,
  michelleStyle,
  mobileStyle,
  mousepadStyle,
  myAlertsStyle,
  navMenuStyle,
  newsletterStyle,
  contactUsStyle,
  santaStyle,
  searchEngineStyle,
  selectStyle,
  serviceByMailStyle,
  slugsStyle,
  specialPromotionStyle,
  tabElementStyle,
  tateStyle,
  teaserStyle,
  timelineArticleStyle,
  topNewsStyle,
  quoteStyle,
  typeConf,
  verticalList,
  shareBarPopup,
  subscribePopupStyle,
  weekendStyle,
  osakaStyle,
  pageDateTime,
  pazuzuStyle,
  panucciStyle,
  paywallStyle,
  paywallMidpageStyle,
  podcastTeasersStyle,
  ratingStyle,
  readingListStyle,
  resetPasswordModalStyle,
  coronaHomeStory,
  zappStyle,
  personalAreaStyle,
  reviewArticleStyle,
  conferenceArticleStyle,

  // I18n
  teaserI18n,
  appPromotionTooli18n,
  theTradeTeaserI18n,
  a11yMenuI18n,
  alertsI18n,
  adsI18n,
  audioPlayerI18n,
  BingI18n,
  breadcrumbsI18n,
  cancellationModalI18n,
  clickTrackerI18n,
  commentI18n,
  commentFormI18n,
  commentSentI18n,
  commentsSectionI18n,
  faceArticleStyle,
  faceExclusivesI18n,
  footerDesktopI18n,
  footerSocialLinksI18n,
  footerMobileListsI18n,
  fryListI18n,
  giftShareI18n,
  greetingsI18n,
  headerSearchI18n,
  hotTopicsI18n,
  homepageLogoLink,
  magazineExclusivesI18n,
  magazineRecipesI18n,
  mastheadSubscriptionI18n,
  mobileAdditionalShare,
  mobileNavigationMenuI18n,
  mobileQuickRegistrationI18n,
  mobileSearchI18n,
  mobileUserMenuI18n,
  mobileReadingList,
  myAlertsI18n,
  navigationMenuI18n,
  newsletterI18n,
  singleNewsletterI18n,
  osakaI18n,
  paywallI18n,
  paywallMiniregI18n,
  paywallPhoneForArticleI18n,
  podcastChannelNames,
  podcastChannelTitle,
  podcastChannelTitleMobile,
  podcastI18n,
  promotionsI18n,
  pushwooshCustomPopup,
  seriesArticleI18n,
  serviceByMailI18n,
  tagsElementI18n,
  textInputI18n,
  userMenuI18n,
  userStoryTopicI18n,
  welcomePageI18n,
  words,
  zenTextI18n,
  zoominText,
  zoomoutText,
  selectAriaLabel,
  seoI18n,
  articleLayoutI18n,
  breakingNewsStrip,
  dayMap,
  coastHeaders,
  commentsCountI18n,
  commercialListI18n,
  galleryI18n,
  liveBlogI18n,
  ratingI18n,
  recipeIngredientsI18n,
  recipeInstructionsI18n,
  reviewRatingI18n,
  recipeRatingI18n,
  recipesSearchI18n,
  authorTooltipI18n,
  conferenceArticleI18n,

  tldrDefaultContent,
  tldrPromotionLink,
  timeDuration,
  timeDurationEmpty,
  topNewsI18n,
  marketingTools,
  survey,
  surveyPie,
  readingListActionsI18n,
  readingListMenuI18n,
  readingListPageI18n,
  timeLabels,
  zenNonPayingUsers,
  printNonPayingUsers,
  readingListDisabled,
  adBlockPage,
  footballHeaders,
  nbaHeaders,
  groupBarTabs,
  playoffsI18n,
  btnOptions,
  shareBarI18n,
  sliderCaptionI18n,
  tateI18n,
  validationMailStrip,
  infographicsI18n,
  algoliaI18n,
  commentsPaywallBlocker,
  labelsI18n,
  errorPageI18n,
  gameArticleI18n,
  personalAreaI18n,
  downloadApplicationPopupI18n,
  resetPasswordModalI18n,
  fomoToolTipI18n,
  // Methods
  color: getColor,
  getDelay,
  getDuration,
  getTimingFunction,
  getTransition,
  getTransitionString,
  getMqString,
  getZIndex,
  mq,
  pxToRem,
  type: typesetter,
});

export default tmTheme;
