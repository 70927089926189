// @flow
/* global window document */

import { useEffect, useState, } from 'react';
import config from 'config';

const NETWORK_CODE = '9401';
const ADUNIT_PLACEHOLDER = '{adUnitCode}';

const SITE_PRODUCTS = Object.freeze({
  htz: [ 243, 274, ],
  tm: [ 273, 274, ],
  hdc: [ 239, ],
});

const MainContentType = {
  section: 'section',
  homepage: 'homepage',
  other: 'other',
};

const FLUID = -9999;
// Converts size [ -9999, 0 ] into 'fluid' literal
export function tranlateFluidSize(size) {
  return (size[0] === FLUID) ? 'fluid' : size;
}

// Get site prduct numbers by site code
export function getSiteProducts(site) {
  return SITE_PRODUCTS[site];
}

// Tests if the Ad is a Fixed-size ad
export const isFixedSize = sizes => (
  !!sizes
  && sizes.length === 1 // is there only one FixedSize
  && sizes[0].length === 2 // is fixed size contains width and height
  && sizes[0][0] > 0 // is width positie (and not 'fluid' size)
  && sizes[0][1] > 0 // is height positive
);


// converts Size into CSS style
export const size2CSS = size => {
  const s = size[0];
  return {
    minWidth: `${s[0]}px`,
    minHeight: `${s[1]}px`,
  };
};

function spinalToCamelCase(key) {
  if (!key) {
    return key;
  }

  return key.replace(/-\w/g, v => v.charAt(1).toUpperCase());
}

// Converts css string to Obj
export function inlineStyle2Obj(inlineStyle) {
  const css = {};

  inlineStyle
    .split(';')
    .map(kv => kv.split(':'))
    .forEach(kv => {
      if (kv?.length === 2) {
        css[spinalToCamelCase(kv[0].trim())] = kv[1].trim();
      }
    });

  return css;
}

// Creates a Media-Query string of (min-width: <width>px) and (max-width: <width>px)
export function toMediaQueryString([ width1, width2, ]) {
  const [ w1, w2, ] = [ width1, width2, ]
    .filter(n => typeof n === 'number')
    .sort((a, b) => a - b);

  return `(min-width:${w1}px${typeof w2 === 'number' ? `) and (max-width:${w2 - 1}px)` : ')'}`;
}


/**
 * Creates a MediaQueryList that triggers event when a viewport has empty sizes array
 * @param sizeMapping The size-mapping object to create an oserver for it
 * @returns MediaQueryList
 */
export function createEmptySizesObserver(sizeMapping) {
  let observer = null;

  if (!sizeMapping?.length) {
    return null;
  }

  const sortedMapping = sizeMapping.sort((sm1, sm2) => sm1.viewport[0] - sm2.viewport[0]);
  const mediaQueries = [];

  for (let i = 0; i < sortedMapping.length; i += 1) {
    if (sortedMapping[i].sizes.length === 0) {
      const width1 = sortedMapping[i].viewport[0];
      const width2 = sortedMapping[i + 1]?.viewport[0];

      mediaQueries.push(toMediaQueryString([ width1, width2, ]));
    }
  }

  if (mediaQueries.length > 0) {
    const mqKey = `${mediaQueries.map(q => `(${q})`).join(' or ')}`;
    observer = window.matchMedia(mqKey);
  }

  return observer;
}

/**
 * The next 2 functions are helpers for  creating hard-coded AdSlots.
 */
// creates an ad-unit-path string template for ad units on current page.
export function useAdUnitPathTemplate() {
  const [ adUnitPathTemplate, setAdUnitPathTemplate, ] = useState(ADUNIT_PLACEHOLDER);

  useEffect(() => {
    const url = new URL(document.URL);
    const sectionPathSegments = url.pathname.split('/').filter(p => !!p);
    const path = [];

    const mainContentType = sectionPathSegments.length === 0
      ? MainContentType.homepage
      : MainContentType.section;

    let currentAdUnitPathSegment = ADUNIT_PLACEHOLDER;

    path.push(`/${NETWORK_CODE}`);
    path.push(config.get('domain'));
    path.push(ADUNIT_PLACEHOLDER);

    currentAdUnitPathSegment += mainContentType === (MainContentType.homepage) ? '_homepage' : '_section';
    path.push(currentAdUnitPathSegment);


    for (const pathSegment of sectionPathSegments) {
      currentAdUnitPathSegment += `.${pathSegment}`;
      path.push(currentAdUnitPathSegment);
    }

    setAdUnitPathTemplate(path.join('/'));
  }, []);

  return adUnitPathTemplate;
}

// creates an ad-unit-path from ad-unit-code and path-template
export function createAdSlotPath(adUnitPathTemplate, adUnitCode) {
  if (typeof adUnitPathTemplate !== 'string') return adUnitPathTemplate;

  return adUnitPathTemplate.replaceAll(ADUNIT_PLACEHOLDER, adUnitCode);
}
