/* global window, document atob */
// import { getSubdomain, } from './domain-utils';
/**
 * Htz-cookie-util
 * @module htzCookieUtil
 * @author Elia Grady elia.grady@haaretz.co.il
 * @license MIT
 */

// Cookie-names that need to be transformed into object
const OBJECT_COOKIES = [ 'user_details', 'userProducts', ];

/**
 * Translates Key-Value string into a convenient map.
 * @param {String} string String in format of "key<operator>value<separator>....."
 * @param {object} options object for overriding defaults:
 * options.separator is a String or regExp that separates between each key value pairs
 * (default is ';'). options.operator is a String or regExp that separates between each key
 * and value within a pair (default is '=').
 * @returns {object} a map-like object, with key-value mapping according to the passed configuration.
 */
export function stringToMap(
  string,
  { separator = /;\s*/, operator = '=', } = {}
) {
  const map = Object.create(null);
  const itemsArr = string.split(separator);
  itemsArr.forEach(element => {
    if (typeof element === 'string') {
      const splitPos = element.indexOf(operator);
      const keyValue = [ element.substring(0, splitPos), element.substring(splitPos + 1), ];

      try {
        map[keyValue[0]] = decodeURIComponent(keyValue[1]);
      }
      catch (e) {
        // Do nothing, malformed URI
      }
    }
  });
  return map;
}

export function getCookieAsString(cookies, key, separator = /;\s*/, operator = '=') {
  return cookies
    .split(separator)
    .filter(cookie => cookie.includes(key))
    .map(extractCookie)
    .map(wrappedDecodeURIComponent)[0];

  function extractCookie(cookieString) {
    const [ _, value, ] = cookieString.split(operator);
    return value;
  }

  function wrappedDecodeURIComponent(value) {
    try {
      return decodeURIComponent(value);
    }
    catch (e) {
      return null;
    }
  }
}

export function b64DecodeUnicode(str) {
  const isServer = typeof document === 'undefined';

  if (isServer) {
    return Buffer.from(str, 'base64').toString('utf-8');
  }

  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
}
let cookieAsMap; // Memoization of cookie parsing

/**
 * Converts a cookie string to a Map-like plain object
 * @param {boolean} forceRefresh - flag to force re-creation of the cookie map.
 * @param {string} cookieVal - a cookie value to parse, defaults to document.cookie
 * @returns {object} the cookie string represented as a key-value pairs
 */
export function getCookieAsMap(
  forceRefresh = false,
  cookieVal = document.cookie
) {
  if (!cookieAsMap || forceRefresh) {
    cookieAsMap = stringToMap(cookieVal, { separator: /;\s?/, });

    for (const cookieName of OBJECT_COOKIES) {
      if (typeof cookieAsMap[cookieName] === 'string') {
        try {
          // eslint-disable-next-line no-underscore-dangle
          cookieAsMap[cookieName] = JSON.parse(b64DecodeUnicode(cookieAsMap[cookieName]));

          if (cookieName === 'user_details' && cookieAsMap?.[cookieName]) {
            cookieAsMap[cookieName].userType = cookieAsMap[cookieName].userType || cookieAsMap[cookieName].type;
          }
        }
        catch (error) {
          console.error(error.message);
        }
      }
    }
  }

  return cookieAsMap;
}

/**
 * Sets a client cookie
 * @param {string} key - cookie name
 * @param {string} value - cookie value
 * @param {string} path - path of cookie
 * @param {string} domain - domain of cookie
 * @param {Date} expiration - date of cookie expiration
 */
export function setCookie(
  key,
  value,
  path,
  domain,
  expiration = new Date(Date.now() + 31536000000) /* one year */
) {
  const params = [];
  const expires = expiration;
  params.push(`${key}=${encodeURIComponent(value)}`);

  if (path) {
    params.push(`path=${path}`);
  }
  if (domain) {
    params.push(`domain=${domain}`);
  }
  params.push(`expires=${expires.toUTCString()}`);

  document.cookie = params.join(';');
  // TODO decide if this side effect is required
  getCookieAsMap(true);
}

export function getCookie(key, forceRefresh = false) {
  return getCookieAsMap(forceRefresh)[key];
}

/**
 * This function plants an http-only cookie for SSO.
 * I works by planting an 'img' tag that when it resolves,
 * the response headers contains a 'Set-Cookie' header field with the tmsso cookie to set.
 * @param imageUrl the special endpoint to request an image from.
 * Can be retrived from an SSO server with a successful 'login' response
 * @return {Promise} that resolves if the image was loaded (and therefore cookie was set).
 */
export function plantCookie(imageUrl) {
  const promise = new Promise((resolve, reject) => {
    const img = document.createElement('img');
    img.classList.add('login-cookie');
    img.src = `${imageUrl}&_ts=${Date.now()}`;
    img.addEventListener('load', () => {
      // const domain = getSubdomain();
      // console.warn(`onload done! domain is: ${domain}`);
      // console.warn('called from old plantCookie! (if you see this, this is bad)');
      // if (img.src.includes(domain)) {
      //   dispatchEvent(window, 'loginstatechange');
      // }
      resolve();
    });
    img.addEventListener('error', () => {
      reject();
    });

    document.body.appendChild(img);
  });

  return promise;
}

// delete and set cookie
export function modifyCookie(key, value, path, domain) {
  document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  setCookie(key, value, path, domain);
}

// Delete all cookies (including paths) helper for testing purposes
export function deleteAllCookies() {
  const cookies = document.cookie.split('; ');
  for (let c = 0; c < cookies.length; c += 1) {
    const domain = window.location.hostname.split('.');
    while (domain.length > 0) {
      const cookieBase = `${encodeURIComponent(
        cookies[c].split(';')[0].split('=')[0]
      )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${domain.join(
        '.'
      )} ;path=`;
      const path = window.location.pathname.split('/');
      document.cookie = `${cookieBase}/`;
      while (path.length > 0) {
        document.cookie = cookieBase + path.join('/');
        path.pop();
      }
      domain.shift();
    }
  }
}

export default getCookieAsMap;
